<template>
  <ul class="menu-nav">
    <template v-for="(item, i) in listItem">
      <router-link
        v-if="item.route"
        :key="i"
        :to="item.route"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-show="item.show"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && !item.submenu && 'menu-item-active',
            isExactActive && !item.submenu && 'menu-item-active',
            item.submenu && 'menu-item-submenu',
            getOpenMenu(item.submenu) && 'menu-item-open'
          ]"
          :key="`${i}-${item.id}`"
        >
          <a
            :href="href"
            class="menu-link"
            @click="navigate"
            v-bind:class="{
              'menu-toggle': item.submenu
            }"
          >
            <i :class="item.icon"></i>
            <span class="menu-text">{{ item.name }}</span>
            <i v-if="item.submenu" class="menu-arrow"></i>
          </a>
          <!-- RENDERING SUBMENU -->
          <div
            v-if="item.submenu"
            class="menu-submenu menu-submenu-classic menu-submenu-right"
          >
            <ul class="menu-subnav">
              <template v-for="(sub, index) in item.submenu">
                <router-link
                  v-if="sub.route"
                  :key="`sub-${index}`"
                  :to="sub.route"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-show="sub.show"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                    :key="`sub-li-${index}`"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i :class="sub.icon"></i>
                      <span class="menu-text">{{ sub.name }}</span>
                    </a>
                  </li>
                </router-link>
              </template>
            </ul>
          </div>
        </li>
      </router-link>
    </template>
  </ul>
</template>

<script>
import SavaDataUtil from '../../../utils/saveDataToLocal';
import _ from 'lodash';
export default {
  name: 'MenuItem',
  props: {
    listItem: Array
  },
  data() {
    return {
      listFeature: []
    };
  },
  created() {
    this.getListFeature();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    getListFeature() {
      this.listFeature = SavaDataUtil.getData('listFeature');
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    getOpenMenu(submenu) {
      if (submenu) {
        const fullPath = this.$route.fullPath;
        const isMatch = _.find(submenu, item => {
          return _.startsWith(fullPath, item.route);
        });
        if (isMatch) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>
