var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},_vm._l((_vm.transformSubMenuItem),function(header,index){return _c('li',{key:`${header.title}_${index}`,staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(header.page) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('router-link',{attrs:{"to":header.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(header.title))]),(header.submenu)?_c('i',{staticClass:"menu-arrow"}):_vm._e()])]}}],null,true)}),(header.submenu)?_c('div',{staticClass:"menu-submenu menu-submenu-fixed"},[_c('div',{staticClass:"menu-subnav megamenu",staticStyle:{"max-width":"500px"}},[_c('ul',{staticClass:"menu-content"},_vm._l((header.submenu),function(submenus,i){return _c('li',{key:i,staticClass:"menu-item"},[(_vm.isTabletSize)?_vm._l((submenus),function(submenu){return _c('router-link',{key:submenu.page,attrs:{"to":submenu.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-heading menu-toggle",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:submenu.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(submenu.name)+" ")])])]}}],null,true)})}):_vm._e(),(!_vm.isTabletSize)?_c('ul',{staticClass:"menu-inner"},_vm._l((submenus),function(submenu){return _c('router-link',{key:submenu.page,attrs:{"to":submenu.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(submenu.show)?_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:submenu.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(submenu.name)+" ")])])]):_vm._e()]}}],null,true)})}),1):_vm._e()],2)}),0)])]):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }