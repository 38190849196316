<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: User Bar -->
    <b-row align-h="between" align-v="stretch">
      <!-- TODO: Điều chỉnh lại css cho button-->
      <!-- <b-col cols="6" v-if="isNewLayout">
        <div
          data-v-6c7fd7ba=""
          class="navi navi-spacer-x-0 p-0 my-2"
          style="margin-right: 12px; margin-top:2px"
        >
          <b-form-select
            size="sm"
            v-model="selectedMerchant"
            :options="merchantOptions"
            style="width: fit-content !important; font-weight: bold; font-size: 12px; padding-right: 4px"
            @change="onChangeMerchant"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Chọn công ty --</b-form-select-option
              >
            </template>
          </b-form-select>
        </div>
      </b-col> -->
      <b-col><KTQuickUser></KTQuickUser></b-col>
    </b-row>
    <template v-if="isNewLayout"><KTMenuOptionsMore></KTMenuOptionsMore></template>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';
import KTMenuOptionsMore from '@/view/layout/header/_MenuOptionsMore.vue';
import SavaDataUtil from '@/utils/saveDataToLocal';

export default {
  name: 'KTTopbar',
  data() {
    return {
      selectedMerchant: 1,
      merchantOptions: [
        { value: 1, text: 'NEG)' },
      ],
    };
  },
  mounted(){
    this.selectedMerchant = SavaDataUtil.getData('merchantId') || 1;
  },
  computed: {
    isNewLayout(){
      if (SavaDataUtil.getData('config')) return true 
      return false;
    },
  },
  components: {
    KTQuickUser,
    KTMenuOptionsMore
  },
  methods: {
    onChangeMerchant() {
      SavaDataUtil.saveData('merchantId', this.selectedMerchant);
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>
