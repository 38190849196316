<template>
  <div class="topbar-item">
    <div
      class="
        btn btn-icon
        w-auto
        d-flex
        align-items-center
        btn-lg
        px-2
      "
      id="kt_quick_user_toggle"
    >
      <span
        v-if="!isTabletSize"
        class="
          font-weight-bold font-size-base
          d-none d-md-inline
        "
        :class="[isNewLayout ? 'text-white' : 'text-dark-50']"
        >Hi,</span
      >
      <span
        v-if="!isTabletSize"
        class="
          font-weight-bolder font-size-base
          d-none d-md-inline
        "
        :class="[isNewLayout ? 'text-white' : 'text-dark-50']"
      >
        {{ employee.fullName }}
        </span
      >
      <span class="symbol symbol-35 symbol-light-success">
        <span v-if="isTabletSize" class="w-auto px-3 symbol-label font-size-h5 font-weight-bold">
          {{ employee.fullName }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3>Thông tin cá nhân</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img src="media/users/default.jpg" alt="" class="symbol-label" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href=""
              class="
                font-weight-bold font-size-h5
                text-dark-75 text-hover-primary
                router-link-exact-active router-link-active
              "
            >
              {{ employee.fullName }}
            </a>
            <div class="text-muted mt-2">
              <i class="fas fa-solid fa-hashtag"></i>
              {{ employee.id }}
            </div>
            <div class="text-muted mt-2">
              <i class="fas fa-mobile-alt" style="margin-right: 5px"></i
              >{{ employee.mobile }}
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-text text-muted text-hover-primary">
                    <i class="fas fa-at"></i> {{ employee.email }}
                  </span></span
                ></a
              >
            </div>
            <!--begin: User Bar -->
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Đăng xuất
            </button>
            <!--end: User Bar -->
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <router-link to="/profile/change-password" class="navi-item">
            <div class="navi-link">
              <div class="navi-text">
                <div class="font-weight-bold">Đổi mật khẩu</div>
                <div class="text-muted">
                  Đổi mật khẩu đăng nhập
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="navi navi-spacer-x-0 p-0">
          <router-link to="/profile/change-store" class="navi-item">
            <div class="navi-link">
              <div class="navi-text">
                <div class="font-weight-bold">Chuyển cửa hàng</div>
                <div class="text-muted">
                  Chuyển cửa hàng của bạn
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="navi navi-spacer-x-0 p-0">
          <router-link to="/profile/builder" class="navi-item">
            <div class="navi-link">
              <div class="navi-text">
                <div class="font-weight-bold">Cấu hình giao diện</div>
                <div class="text-muted">
                  Chuyển đổi bố cục app
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="navi navi-spacer-x-0 p-0">
            <div class="navi-link" v-if="checkStoreManager()">
              <div class="navi-text">
                <div class="font-weight-bold text-hover-primary" type="button" @click="changeAccessStatusStore">{{ actionChangeStatus }}</div>
                <div class="text-muted">
                  {{ actionChangeStatus }} {{ accessInfo.Store?.name }}
                </div>
              </div>
            </div>
        </div>
        <div class="separator separator-dashed my-7"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from '@/core/services/store/modules/auth.module';
import { RESET_MENU_WHEN_LOGOUT } from '@/core/services/store/modules/menu.module';
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import { getData } from '@/utils/saveDataToLocal';
import KTUtil from '@/assets/js/components/util';
import ApiService from '@/core/services/api.service';
import { JOB_TITLE, USER_ACCESS_STATUS } from '@/utils/enum';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import { showConfirmDialog } from '@/utils/sweet-alert2'
export default {
  name: 'KTQuickUser',
  data() {
    return {
      employee: {},
      isTabletSize: KTUtil.isBreakpointDown('lg'),
      selectedMerchant: 1,
      merchantOptions: [
        { value: 1, text: 'DiDongViet (DDV)' },
        { value: 2, text: 'TheGioiMayCu (TGMC)' },
      ],
      storeAccessStatus: null,
      actionChangeStatus: '',
      accessInfo: {},
      isBusy: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkTabletSize);
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
    this.employee = this.getEmployee();
    this.$nextTick(() => {
      window.addEventListener('resize', this.checkTabletSize);
    });
    if (this.checkStoreManager()) {
      this.getAccessStore();
    }
  },
  methods: {
    showConfirmDialog,
    checkTabletSize() {
      this.isTabletSize = KTUtil.isBreakpointDown('lg');
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }));
      this.$store.dispatch(RESET_MENU_WHEN_LOGOUT);
    },
    getEmployee() {
      const employeeInfo = getData('employee_info');
      if (employeeInfo) {
        const tmpInfo = JSON.parse(employeeInfo);
        return tmpInfo;
      }
      return { fullName: 'Bạn' };
    },
    checkStoreManager() {
      const isPassPermission = [JOB_TITLE.QL, JOB_TITLE.TL];
      return isPassPermission.includes(this.employee.jobTitleId);
    },
    async changeAccessStatusStore() {
      if (this.onBusy) return;

      if(!this.accessInfo){
         return makeToastFaile('Cấu hình IP cửa hàng không tồn tại hoặc bị xoá!, liên hệ IT!');
      }
      const descModal = this.storeAccessStatus === USER_ACCESS_STATUS.ACTIVE
        ? 'Chỉ cho phép những User sử dụng wifi tại cửa hàng để truy cập vào hệ thống!'
        : 'Tắt giới hạn IP, User có thể đăng nhập khi không ở cửa hàng. Lưu ý thao tác có hiệu lực trong ngày!'
      this.showConfirmDialog(`${this.actionChangeStatus} ${this.accessInfo.Store?.name} ?`, descModal)
        .then(async () => {
          try {
            this.isBusy = true
            const response = await ApiService.put(
              '/user-accesses/changeStatus',
              {
                id: this.accessInfo.id,
              },
            );
            const { message } = response.data;
            makeToastSuccess(message || 'Thành công');
            this.getAccessStore();
            this.isBusy= false
          } catch (err) {
            this.isBusy= false
            const { message } = err.response;
            makeToastFaile(message || 'Lỗi');
          }
        })
        .catch(err => {
          console.log('err::: ', err)
        })
    },
    async getAccessStore() {
      const { storeId } = this.employee;
      const params = {
        storeId,
      };
      try {
        const response = await ApiService.query('user-accesses/option', { params });
        this.accessInfo = response.data.data;
        this.storeAccessStatus = this.accessInfo?.status || null;
        this.actionChangeStatus =
          this.storeAccessStatus === USER_ACCESS_STATUS.ACTIVE
            ? 'Kích hoạt'
            : 'Tắt';
        this.actionChangeStatus +=' giới hạn IP cửa hàng';
      } catch (err) {
        const { message } = err.response.data;
        console.log('message::: ', message);
      }
    },
  },
  computed: {
    isNewLayout(){
      if (getData('config')) return true 
      return false;
    },
  }
};
</script>
