<template>
  <ul class="menu-nav">
    <li
      v-for="(header, index) in transformSubMenuItem"
      :key="`${header.title}_${index}`"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren(header.page) }"
    >
      <router-link :to="header.page" v-slot="{ href, navigate }">
        <a :href="href" class="menu-link menu-toggle" @click="navigate">
          <span class="menu-text">{{ header.title }}</span>
          <i class="menu-arrow" v-if="header.submenu"></i>
        </a>
      </router-link>
      <div class="menu-submenu menu-submenu-fixed" v-if="header.submenu">
        <div class="menu-subnav megamenu" style="max-width: 500px">
          <ul class="menu-content">
            <li
              class="menu-item"
              v-for="(submenus, i) in header.submenu"
              :key="i"
            >
              <template v-if="isTabletSize">
                <router-link
                  v-for="submenu in submenus"
                  :key="submenu.page"
                  :to="submenu.page"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <a
                    :href="href"
                    class="menu-heading menu-toggle"
                    @click="navigate"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <i :class="submenu.icon"></i>
                    <span class="menu-text"> {{ submenu.name }} </span>
                  </a>
                </router-link>
              </template>

              <ul class="menu-inner" v-if="!isTabletSize">
                <router-link
                  v-for="submenu in submenus"
                  :key="submenu.page"
                  :to="submenu.page"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    v-if="submenu.show"
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i :class="submenu.icon"></i>
                      <span class="menu-text"> {{ submenu.name }} </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { headerMenu } from '@/core/config/menu/headerMenu.config';
import KTUtil from '@/assets/js/components/util';
export default {
  name: 'KTMenuDropDown',
  data() {
    return {
      headerMenu,
      isTabletSize: KTUtil.isBreakpointDown('lg')
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.checkTabletSize);
      this.$on('hook:beforeDestroy', () => {
        window.removeEventListener('resize', this.checkTabletSize);
      });
    });
  },
  computed: {
    transformSubMenuItem() {
      const headerMenu = [...this.headerMenu.items];
      const showedHeaderMenu = (headerMenu || []).filter(item => item.show);
      const newSubMenu = [];

      for (const headerMenuItem of showedHeaderMenu) {
        if (!headerMenuItem.submenu) {
          newSubMenu.push(headerMenuItem);
          continue;
        }
        const submenus = headerMenuItem.submenu.filter(item => item.show);
        const convertedSubMenu = this.chunkSubMenuItem(submenus);
        headerMenuItem.submenu = convertedSubMenu;
        newSubMenu.push(headerMenuItem);
      }
      return newSubMenu;
    }
  },
  methods: {
    checkTabletSize() {
      this.isTabletSize = KTUtil.isBreakpointDown('lg');
    },
    chunkSubMenuItem(submenuItems) {
      if (!submenuItems || !submenuItems.length) return [];

      const results = [];
      let CHUNK_SIZE = 8;
      if (submenuItems.length > CHUNK_SIZE * 2) {
        CHUNK_SIZE = CHUNK_SIZE + (submenuItems.length - CHUNK_SIZE * 2);
      }
      if (submenuItems <= CHUNK_SIZE) return submenuItems;

      while (submenuItems.length) {
        results.push(submenuItems.splice(0, CHUNK_SIZE));
      }
      return results;
    },

    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    }
  }
};
</script>
