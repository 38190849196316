<template>
  <ul class="menu-nav">
    <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Trang chủ</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/stores"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('STORE_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-supermarket"></i>
          <span class="menu-text">Cửa hàng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/order-source"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('ORDER_SOURCE_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-delivery-package"></i>
          <span class="menu-text">Nguồn đơn hàng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/customers"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('CUSTOMER_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-group"></i>
          <span class="menu-text">Khách hàng</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('CATE_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-menu-1"></i>
          <span class="menu-text">Danh mục sản phẩm</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link to="/suppliers" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand"></i>
          <span class="menu-text">Nhà cung cấp</span>
        </a>
      </li>
    </router-link>
    <router-link to="/products" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand"></i>
          <span class="menu-text">Sản phẩm</span>
        </a>
      </li>
    </router-link>-->
    <router-link
      to="/permissions"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('ROLE_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-settings"></i>
          <span class="menu-text">Phân quyền</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('USER_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user"></i>
          <span class="menu-text">Tài khoản</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/employees"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('EMPLOYEE_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user-outline-symbol"></i>
          <span class="menu-text">Nhân sự</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/brands"
      v-show="checkPermission('BRAND_VIEW')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-bell-1"></i>
          <span class="menu-text">Thương hiệu</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/providers"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('PROVIDER_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-delivery-truck"></i>
          <span class="menu-text">Nhà cung cấp</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/properties"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-show="checkPermission('PROPERTY_VIEW')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-tools-and-utensils"></i>
          <span class="menu-text">Thuộc tính sản phẩm</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import SavaDataUtil from '../../../utils/saveDataToLocal';
import _ from 'lodash';
export default {
  name: 'KTMenu',
  data() {
    return {
      listFeature: []
    };
  },
  created() {
    this.getListFeature();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    getListFeature() {
      this.listFeature = SavaDataUtil.getData('listFeature').split(',');
    },
    checkPermission(condition) {
      let conditions = [];
      if (_.isArray(condition)) {
        conditions = condition;
      } else {
        conditions = [condition];
      }
      const isShow = this.listFeature.some(f => conditions.indexOf(f) >= 0);
      return isShow;
    }
  }
};
</script>
